import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

function Protected({ children }) {
  const isSignedIn = useSelector((state) => state.persistedAuthReducer.user);

  if (!isSignedIn) {
    return <Navigate to="/login" replace />;
  }

  return children;
}
export default Protected;
