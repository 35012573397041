import { Icon } from "@chakra-ui/react";
import React from "react";

export const Footer = ({ className = "" }) => {
  return (
    <div
      className={`flex gap-2 flex-col-reverse items-center md:flex-row md:items-center md:justify-between footer py-5 ${className}`}
    >
      <p>Copyright Quick Recap &#x00A9; 2023</p>
      <a
        href="https://x.com/quickrecapai?s=21"
        target="_blank"
        rel="noreferrer"
        className="flex  items-center justify-center md:mr-28 lg:mr-20"
      >
        follow us on
        <Icon boxSize={3.5} mx={2}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            shape-rendering="geometricPrecision"
            text-rendering="geometricPrecision"
            image-rendering="optimizeQuality"
            fill-rule="evenodd"
            clip-rule="evenodd"
            viewBox="0 0 512 462.799"
          >
            <path
              fill-rule="nonzero"
              d="M403.229 0h78.506L310.219 196.04 512 462.799H354.002L230.261 301.007 88.669 462.799h-78.56l183.455-209.683L0 0h161.999l111.856 147.88L403.229 0zm-27.556 415.805h43.505L138.363 44.527h-46.68l283.99 371.278z"
            />
          </svg>
        </Icon>
        /{" "}
        <Icon boxSize={4} mx={2}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            shape-rendering="geometricPrecision"
            text-rendering="geometricPrecision"
            image-rendering="optimizeQuality"
            fill-rule="evenodd"
            clip-rule="evenodd"
            viewBox="0 0 640 640"
          >
            <path d="M640.012 121.513c-23.528 10.524-48.875 17.516-75.343 20.634 27.118-16.24 47.858-41.977 57.756-72.615-25.347 14.988-53.516 25.985-83.363 31.866-24-25.5-58.087-41.35-95.848-41.35-72.508 0-131.21 58.736-131.21 131.198 0 10.228 1.134 20.232 3.355 29.882-109.1-5.528-205.821-57.757-270.57-137.222a131.423 131.423 0 0 0-17.764 66c0 45.497 23.102 85.738 58.347 109.207-21.508-.638-41.74-6.638-59.505-16.359v1.642c0 63.627 45.225 116.718 105.32 128.718-11.008 2.988-22.63 4.642-34.606 4.642-8.48 0-16.654-.874-24.78-2.35 16.783 52.11 65.233 90.095 122.612 91.205-44.989 35.245-101.493 56.233-163.09 56.233-10.63 0-20.988-.65-31.334-1.89 58.229 37.359 127.206 58.997 201.31 58.997 241.42 0 373.552-200.069 373.552-373.54 0-5.764-.13-11.35-.366-16.996 25.642-18.343 47.87-41.493 65.469-67.844l.059-.059z" />
          </svg>
        </Icon>
      </a>
      <a
        href="https://www.iubenda.com/privacy-policy/35078713"
        target="_blank"
        className="underline decoration-ytred decoration-2"
        rel="noreferrer"
      >
        Privacy Policy
      </a>
    </div>
  );
};
