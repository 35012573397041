import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <h1 className="text-3xl font-bold my-5">Privacy Policy</h1>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Obcaecati,
        laborum eligendi. Voluptatibus officia sapiente voluptates repellendus,
        alias doloremque delectus voluptatum dignissimos, praesentium id,
        veritatis sequi distinctio perspiciatis deleniti debitis cum?
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. In consectetur
        aperiam quod molestias odio ea dolor assumenda repudiandae autem quidem
        adipisci beatae eius blanditiis unde est, ipsa quae perspiciatis quis!
      </p>
    </div>
  );
};

export default PrivacyPolicy;
