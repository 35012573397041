import React from "react";
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  const error = useRouteError();

  return (
    <div className="flex flex-col justify-center items-center mx-auto min-h-screen">
      <h1 className="text-3xl font-bold">Oops! 😭</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p>
        <i className="text-ytred">{error.statusText || error.message}</i>
      </p>
    </div>
  );
}
