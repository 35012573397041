import Protected from "components/Protected/Protected";
import React from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import AdminProtected from "./components/AdminProtected/AdminProtected";
import ErrorPage from "./pages/ErrorPage/ErrorPage";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        lazy: async () => {
          let { Home } = await import("./pages/Home/Home");
          return { Component: Home };
        },
      },
      {
        path: "/summary/:id",
        lazy: async () => {
          let { UserSummary } = await import("./pages/UserSummary/UserSummary");
          return {
            element: (
              <Protected>
                <UserSummary />
              </Protected>
            ),
          };
        },
      },
      {
        path: "/summary/:summaryId/share",
        lazy: async () => {
          let { RefSummary } = await import("./pages/RefSummary/RefSummary");
          return { Component: RefSummary };
        },
      },
      {
        path: "/refer-a-friend",
        lazy: async () => {
          let { UserReferal } = await import("./pages/UserReferal/UserReferal");
          return {
            element: (
              <Protected>
                <UserReferal />
              </Protected>
            ),
          };
        },
      },
      {
        path: "/summary",
        lazy: async () => {
          let { Summary } = await import("pages/SummaryPage/Summary");
          return {
            element: <Summary />,
          };
        },
      },
      {
        path: "/privacy-policy",
        lazy: () => {
          return { Component: PrivacyPolicy };
        },
      },
      {
        path: "/login",
        lazy: async () => {
          let { Login } = await import("./pages/Login/Login");
          return { Component: Login };
        },
      },
      {
        path: "/access-denied",
        element: (
          <div>
            <h1>Access Denied 🚨 - Log In as admin</h1>
          </div>
        ),
      },
      {
        path: "/summary-history",
        lazy: async () => {
          let { UserSummaryHistory } = await import(
            "pages/UserSummaryHistory/UserSummaryHistory"
          );
          return {
            element: <UserSummaryHistory />,
          };
        },
      },
      {
        path: "/sign-up",
        lazy: async () => {
          let { SignUp } = await import("./pages/SignUp/SignUp");
          return {
            Component: SignUp,
          };
        },
      },
      {
        path: "/admin-prompt",
        lazy: async () => {
          let { AdminChangePrompt } = await import(
            "./pages/AdminChangePrompt/AdminChangePrompt"
          );
          return {
            element: (
              <AdminProtected>
                <AdminChangePrompt />
              </AdminProtected>
            ),
          };
        },
      },
      {
        path: "/admin-dashboard",
        lazy: async () => {
          let { AdminDashboard } = await import(
            "./pages/AdminDashboard/AdminDashboard"
          );
          return {
            element: (
              <AdminProtected>
                <AdminDashboard />
              </AdminProtected>
            ),
          };
        },
      },
      {
        path: "/admin-dashboard/user/history/:userId",
        lazy: async () => {
          let { AdminUserSummaryData } = await import(
            "./pages/AdminUserSummaryData/AdminUserSummaryData"
          );
          return {
            element: (
              <AdminProtected>
                <AdminUserSummaryData />
              </AdminProtected>
            ),
          };
        },
      },
      {
        path: "/feedbacks",
        lazy: async () => {
          let { Feedbacks } = await import("./pages/Feedbacks/Feedbacks");
          return {
            element: (
              <AdminProtected>
                <Feedbacks />
              </AdminProtected>
            ),
          };
        },
      },
      {
        path: "/confirm-email",
        lazy: async () => {
          let { EmailConfirmation } = await import(
            "pages/EmailConfirmation/EmailConfirmation"
          );
          return {
            element: <EmailConfirmation />,
          };
        },
      },
      {
        path: "/verify-email",
        lazy: async () => {
          let { EmailVerified } = await import(
            "pages/EmailVerified/EmailVerified"
          );
          return {
            element: <EmailVerified />,
          };
        },
      },
      {
        path: "/forgotten-password",
        lazy: async () => {
          let { ForgotPassword } = await import(
            "pages/ForgotPassword/ForgotPassword"
          );
          return {
            element: <ForgotPassword />,
          };
        },
      },
      {
        path: "/reset-password",
        lazy: async () => {
          let { ResetPassword } = await import(
            "pages/ResetPassword/ResetPassword"
          );
          return {
            element: <ResetPassword />,
          };
        },
      },
      {
        path: "/pricing",
        lazy: async () => {
          let { Pricing } = await import("./pages/Pricing/Pricing");
          return {
            element: <Pricing />,
          };
        },
      },
      {
        path: "/manage-plans",
        lazy: async () => {
          let { ManagePlans } = await import("./pages/ManagePlans/ManagePlans");
          return {
            element: (
              <Protected>
                <ManagePlans />
              </Protected>
            ),
          };
        },
      },
      {
        path: "/loading-payment",
        lazy: async () => {
          let { LoadingPayment } = await import(
            "./components/LoadingPayment/LoadingPayment"
          );
          return {
            element: (
              <Protected>
                <LoadingPayment />
              </Protected>
            ),
          };
        },
      },
      {
        path: "/support",
        lazy: async () => {
          let { Support } = await import("pages/Support/Support");
          return {
            element: <Support />,
          };
        },
        children: [
          {
            path: "contact-us",
            lazy: async () => {
              let { ContactForm } = await import(
                "components/ContactForm/ContactForm"
              );
              return { element: <ContactForm /> };
            },
          },
          {
            path: "report-bug",
            lazy: async () => {
              let { BugReportForm } = await import(
                "components/BugReportForm/BugReportForm"
              );
              return {
                element: <BugReportForm />,
              };
            },
          },
        ],
      },
    ],
  },
]);
