import axios from "axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { history } from "../../utils/history";

export const authClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true, // required to handle the CSRF token
});

const initialState = {
  user: null,
  status: "idle",
  error: null,
};

export const asyncLogout = createAsyncThunk(
  "api/logout",
  async (payload, { dispatch }) => {
    try {
      await authClient.post("/logout");
      dispatch(guest());
    } catch (error) {
      return error.response.data.message;
    }
  }
);

export const asyncLogin = createAsyncThunk(
  "auth/login",
  async (payload, { dispatch }) => {
    try {
      await authClient.get("/sanctum/csrf-cookie");
      await authClient.post("/login", payload);
      dispatch(asyncGetUser());
    } catch (error) {
      return error.response.data.message;
    }
  }
);

export const asyncGoogleLogin = createAsyncThunk(
  "auth/google-login",
  async (payload, { dispatch }) => {
    try {
      await authClient.get("/sanctum/csrf-cookie");
      await authClient.post("api/google/login", payload);
      dispatch(asyncGetUser());
    } catch (error) {
      return error.response.data.message;
    }
  }
);

export const asyncGetUser = createAsyncThunk(
  "auth/getAuthUser",
  async (payload) => {
    try {
      const res = await authClient.get("api/users/auth");
      return res.data;
    } catch (error) {
      return error.response.data.message;
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    guest: () => {
      const storageItem = window.localStorage.getItem("guest");
      if (!storageItem) return false;
      if (storageItem === "isGuest") return true;
      if (storageItem === "isNotGuest") return false;
    },
    userIsVerified: (state) => {
      state.user.emailVerified = true;
    },
    updateMinutesLeft: (state, action) => {
      state.user.subscription.minutes = action.payload;
    },
    setSubscription: (state, action) => {
      state.user.subscription = action.payload;
    },
    logout: (state) => {
      state.user = null;
    },
    clearUserState: (state) => {
      state.user = null;
    },
  },
  extraReducers(builder) {
    builder.addCase(asyncLogin.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(asyncLogin.fulfilled, (state, action) => {
      state.status = "succeded";
      state.error = action.payload ? action.payload : null;
    });
    builder.addCase(asyncLogin.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    builder.addCase(asyncGetUser.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(asyncGetUser.fulfilled, (state, action) => {
      state.status = "success";
      state.user = action.payload.data;
      history.navigate("/");
    });
    builder.addCase(asyncGetUser.rejected, (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    });

    builder.addCase(asyncLogout.fulfilled, (state, action) => {
      state.user = null;
      state.status = "success";
    });
  },
});

// SELECTORS
export const selectUser = (state) => state.persistedAuthReducer.user;
export const selectStatus = (state) => state.persistedAuthReducer.status;
export const selectError = (state) => state.persistedAuthReducer.error;
export const selectSubscription = (state) =>
  state.persistedAuthReducer.state.isSubscribed;

export const {
  guest,
  userIsVerified,
  setSubscription,
  updateMinutesLeft,
  logout,
  clearUserState,
} = authSlice.actions;

export default authSlice.reducer;
