import { configureStore } from "@reduxjs/toolkit";
import authReducer, { authClient, logout } from "features/auth/authSlice";
import summaryReducer from "features/summary/summarySlice";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";

import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

authClient.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (
      error.response &&
      (error.response.status === 401 || error.response.status === 419)
    ) {
      appStore.dispatch(logout());
    }
    return Promise.reject(error);
  }
);

const persistConfig = {
  key: "auth",
  version: 1,
  storage,
};

const persitSummaryConfig = {
  key: "summary",
  version: 1,
  storage,
};

const persistedAuthReducer = persistReducer(persistConfig, authReducer);
const persistedSummaryReducer = persistReducer(
  persitSummaryConfig,
  summaryReducer
);
export const appStore = configureStore({
  reducer: {
    persistedAuthReducer,
    persistedSummaryReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(appStore);
